$item-background: black;

@font-face {
  font-family: "headerFont";
  src: url("https://s3-ap-southeast-2.amazonaws.com/3dcocktails.viewa/fonts/jaapokki-regular.eot"); /* IE9 Compat Modes */
  src: url("https://s3-ap-southeast-2.amazonaws.com/3dcocktails.viewa/fonts/jaapokki-regular.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */
      url("https://s3-ap-southeast-2.amazonaws.com/3dcocktails.viewa/fonts/jaapokki-regular.woff")
      format("woff"),
    /* Pretty Modern Browsers */
      url("https://s3-ap-southeast-2.amazonaws.com/3dcocktails.viewa/fonts/jaapokki-regular.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("https://s3-ap-southeast-2.amazonaws.com/3dcocktails.viewa/fonts/jaapokki-regular.svg#JaapokkiRegular")
      format("svg"); /* Legacy iOS */
}

html,
body {
  height: 100%;
}

.App {
  height: 100vh; /* Fallback for browsers that do not support Custom Properties */
  height: calc(var(--vh, 1vh) * 100);
  display: flex;
  flex-direction: column;
  max-width: 800px;
}

main {
  display: grid;
  grid-template-columns: repeat(1, minmax(200px, 1fr));
  grid-gap: 1rem;
  padding: 0.1rem 2rem 2rem 2rem;
}

header {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;

  a {
    text-decoration: none;
    color: black;
  }
  height: 110px;
  min-height: 110px;
  max-height: 110px;
}

.item {
  display: flex;
  padding: 1rem;
  height: 200px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: $item-background;
  border-radius: 15px;
  border: 1px grey solid;

  h3 {
    flex: 1;
  }

  h4 {
    justify-self: flex-end;
    margin-bottom: 0px;
  }

  img {
    height: 100%;
    width: auto;
  }
}

.item[explode="1"] {
  animation: explode 1.2s 1;
}

@keyframes explode {
  10% {
    transform: rotate(10deg);
  }
  20% {
    transform: rotate(-20deg);
  }

  30% {
    transform: rotate(0deg);
  }

  100% {
    transform: scale(7.5);
  }
}

@keyframes implode {
  0% {
    transform: scale(1.5);
  }

  100% {
    transform: scale(1);
  }
}

#item-model-viewer {
  cursor: -webkit-grab;
  display: flex;
  height: 100%;
  overflow: hidden;
  position: absolute;
  user-select: none;
  width: 100%;
}

.Hotspot {
  background: #fff;
  border-radius: 32px;
  border: 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
  box-sizing: border-box;
  cursor: pointer;
  height: 24px;
  padding: 8px;
  position: relative;
  transition: opacity 0.3s;
  width: 24px;
}

.Hotspot:not([data-visible]) {
  background: transparent;
  border: 4px solid #fff;
  box-shadow: none;
  height: 32px;
  pointer-events: none;
  width: 32px;
}

.Hotspot:focus {
  border: 4px solid rgb(0, 128, 200);
  height: 32px;
  outline: none;
  width: 32px;
}

.Hotspot > * {
  opacity: 1;
  transform: translateY(-50%);
}

.HotspotAnnotation {
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
  color: rgba(0, 0, 0, 0.8);
  display: block;
  font-family: Futura, Helvetica Neue, sans-serif;
  font-size: 18px;
  font-weight: 700;
  left: calc(100% + 1em);
  max-width: 128px;
  overflow-wrap: break-word;
  padding: 0.5em 1em;
  position: absolute;
  top: 50%;
  width: max-content;
}

.Hotspot:not([data-visible]) > * {
  opacity: 0;
  pointer-events: none;
  transform: translateY(calc(-50% + 4px));
  transition: transform 0.3s, opacity 0.3s;
}

a:visited {
  text-decoration: none;
  color: black;
}

@-webkit-keyframes shake-horizontal {
  0%,
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  10%,
  30%,
  50%,
  70% {
    -webkit-transform: translateX(-3px);
    transform: translateX(-3px);
  }
  20%,
  40%,
  60% {
    -webkit-transform: translateX(3px);
    transform: translateX(3px);
  }
  80% {
    -webkit-transform: translateX(2px);
    transform: translateX(2px);
  }
  90% {
    -webkit-transform: translateX(-2px);
    transform: translateX(-2px);
  }
}
@keyframes shake-horizontal {
  0%,
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  10%,
  30%,
  50%,
  70% {
    -webkit-transform: translateX(-3px);
    transform: translateX(-3px);
  }
  20%,
  40%,
  60% {
    -webkit-transform: translateX(3px);
    transform: translateX(3px);
  }
  80% {
    -webkit-transform: translateX(2px);
    transform: translateX(2px);
  }
  90% {
    -webkit-transform: translateX(-2px);
    transform: translateX(-2px);
  }
}

.shake-horizontal {
  -webkit-animation: shake-horizontal 2s cubic-bezier(0.455, 0.03, 0.515, 0.955)
    0.5s infinite both;
  animation: shake-horizontal 2s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0.5s
    infinite both;
}
